// Variables

$container-max-widths: (md: 720px,
  lg: 960px,
  xl: 1200px);

//- Override and add to color system

$black: #000000;
$red: #cc0000;
$green: #017a0b;
$light-gray: #666666;
$dark-gray: #212529;
$pink: #c71c69;

//- Override primary color
$primary: $pink;
$secondary: $black;
$third-color: $green;

//- Override font system
$font-family-sans-serif: 'Almarai', sans-serif;
$font-family-serif: 'Almarai', sans-serif;
$font-family-base: $font-family-serif;
$headings-font-family: $font-family-sans-serif;

//- Add custom variables
$navbar-height: 4.9rem;

$btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
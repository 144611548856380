// Styling for the masthead

header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - #{$navbar-height});
  // replace the masthead.jpg image in the /assets/img directory to change the image
  // if the image name change, make sure to update the url path below!
  background-image: linear-gradient(
      to bottom,
      #{fade-out($secondary, 0.3)} 0%,
      #{fade-out($secondary, 0.5)} 100%
    ),
    url("../img/main-header-bg.jpg");;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  h1 {
    font-size: 2.25rem;
  }
  @include media-breakpoint-up(lg) {
    height: 100vh;
    min-height: 40rem;
    padding-top: $navbar-height;
    padding-bottom: 0;
    p {
      font-size: 1.15rem;
    }
    h1 {
      font-size: 3rem;
    }
  }
  @include media-breakpoint-up(xl) {
    h1 {
      font-size: 3.5rem;
    }
  }
}

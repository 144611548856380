// Import Google fonts

// @import url("//fonts.googleapis.com/earlyaccess/droidarabickufi.css");
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
// $font-family-sans-serif: 'Droid Arabic Kufi', sans-serif;

// @import url('//fonts.googleapis.com/earlyaccess/notonaskharabicui.css');
// $font-family-sans-serif: 'Noto Naskh Arabic UI', sans-serif;

// Import saa fonts for icons
// @import "./../fonts/saa-font/style.css";

// Import custom variables and override default Bootstrap variables
@import "./variables.scss";

// Import Bootstrap
@import "bootstrap-v4-rtl/scss/bootstrap-rtl.scss";

// Import React Image Gallery styles
@import "~react-image-gallery/styles/scss/image-gallery.scss";

// Import custom styles
@import "./global.scss";
@import "./navbar.scss";
@import "./masthead.scss";

// Import custom utilities
@import "./utilities.scss";

.bg-light {
  background: rgb(245, 245, 245) !important;
}

.saa_shadow {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

// Overriding base styling for the navbar
#mainNav {
  // min-height: $navbar-height;
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 0.8rem;
        &.btn {
          color: $white !important;
          &:hover {
            color: $white !important;
          }
          &:active {
            color: $white !important;
          }
        }
        &:active {
          color: $primary;
        }
        &.active {
          color: $primary !important;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 0.6rem 1rem;
          &.btn {
            color: $white !important;
            margin-top: 0 !important;
            padding: 10px 30px !important;
            &:hover {
              color: $white !important;
            }
            &:active {
              color: $white !important;
            }
          }
        }
      }
    }
  }
}

.init-fail {
  position: fixed;
  top: 56px;
  z-index: 999999;
  border: none;
  border-radius: 0;
  width: 100%;
  text-align: center;
  font-size: 15px;
  padding: 15px;
}

// Scroll to top button
.scroll-to-top {
  outline: none !important;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: block;
  width: 56px;
  height: 56px;
  text-align: center;
  color: $white;
  border: none;
  background: fade-out($primary, 0.5);
  z-index: 2;
  border-radius: 100% !important;
  &:focus,
  &:hover {
    color: white;
  }
  &:hover {
    background: $primary;
  }
  i {
    font-weight: 800;
  }
}
.open-causes-area {
  min-height: 825px;
}

div#navbarResponsive.navbar-collapse.collapse {
  min-height: 40px;
}

.btn-xl {
  padding: 1.1rem 2.25rem;
}

.footer {
  min-height: 431px;
  ul {
    &.quicklinks {
      text-align: center;
      font-size: 90%;
      line-height: 40px;
      margin-bottom: 0;
      text-transform: none;
      a {
        color: #919191;
        &:hover {
          color: #919191;
        }
      }
    }
  }

  ul {
    &.social-buttons {
      margin-bottom: 0;
      li {
        a {
          font-size: 20px;
          line-height: 40px;
          display: block;
          width: 40px;
          height: 40px;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          transition: all 0.3s;
          color: $light-gray;
          border-radius: 100%;
          outline: none;
          background-color: $dark-gray;
          &:active {
            color: $white;
            background-color: $primary;
          }
          &:focus {
            color: $white;
            background-color: $primary;
          }
          &:hover {
            color: $white;
            background-color: $primary;
          }
          i {
            svg {
              margin-top: 9px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.top-main-logo {
  // height: 54px;
}
.footer {
  background: #000;
}

.panel-section {
  min-height: 375px;
}

@media (max-width: 767px) {
  .footer {
    min-height: 838px;
  }
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

.footer .footer_top .footer_widget .footer_title {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  // text-transform: capitalize;
  margin-bottom: 15px;
  border-bottom: 1px solid #333;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .footer .footer_top .footer_widget .footer_title {
    margin-bottom: 20px;
  }
}

.footer .footer_top .footer_widget .footer_logo {
  margin-bottom: 15px;
  border-bottom: 1px solid #333;
  padding-bottom: 15px;
  height: 42px;
}

.footer .footer_top .footer_widget .footer_logo .logo {
  display: block;
}

.footer .footer_top .footer_widget .footer_logo .logo img {
  margin-top: -10px;
}

@media (max-width: 767px) {
  .footer .footer_top .footer_widget .footer_logo {
    margin-bottom: 20px;
  }
}

.footer .footer_top .footer_widget p {
  color: #919191;
  font-size: 14px;
  font-weight: 400;
}

.footer .footer_top .footer_widget p a {
  color: #919191;
}

/* line 53, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p a:hover {
  color: #fff;
}

/* line 59, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text {
  font-size: 16px;
  color: #b2b2b2;
  margin-bottom: 23px;
  font-weight: 400;
  line-height: 28px;
}

/* line 65, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text a.domain {
  color: #b2b2b2;
  font-weight: 400;
}

/* line 68, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text a.domain:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
}

/* line 74, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a {
  font-weight: 500;
  color: #b2b2b2;
}

/* line 78, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
}

/* line 82, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a.first {
  margin-bottom: 10px;
}

/* line 90, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li {
  color: #919191;
  font-size: 13px;
  line-height: 35px;
}

/* line 94, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */

.footer .footer_top .footer_widget a,
.footer .footer_top .footer_widget ul li a {
  color: #919191;
  font-weight: 400;
  // font-family: "Raleway", sans-serif;
}

/* line 98, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget a:hover,
.footer .footer_top .footer_widget ul li a:hover {
  color: #fff;
}

.footer .footer_top .footer_widget {
  .footer-contact {
    a {
      height: 25px;
      display: block;
      margin-bottom: 5px;
    }
  }
}

.newsletter_form {
  .custom-checkbox {
    label {
      font-size: 13px;
      color: #919191;
      line-height: 26px;
    }
  }
  .newslwtter_form_msg {
    margin: 10px 0 0;
    padding: 10px;
    font-size: 13px;
    line-height: 26px;
    &.success {
      border: 1px solid #049551;
      color: #049551;
    }
    &.error {
      border: 1px solid red;
      color: red;
    }
  }
}
/* line 104, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form {
  position: relative;
  margin-bottom: 20px;
}

/* line 107, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input {
  width: 100%;
  height: 50px;
  background: transparent;
  padding-left: 20px;
  font-size: 15px;
  color: #fff;
  border: none;
  border: 1px solid #424242;
  border-radius: 5px;
  // font-family: "Raleway", sans-serif;
}

/* line 118, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input::placeholder {
  font-size: 14px;
  color: #919191;
}

/* line 122, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input:focus {
  outline: none;
}

/* line 126, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form button {
  position: absolute;
  top: 0;
  height: 40px;
  border: none;
  font-size: 14px;
  padding: 10px;
  padding: 0 22px;
  cursor: pointer;
  border-radius: 5px;
  top: 5px;
  right: 5px;
  font-size: 13px;
  font-weight: 500;
}

.rtl .footer .footer_top .footer_widget .newsletter_form button {
  right: auto;
  left: 5px;
}

/* line 146, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_text {
  font-size: 13px;
  color: #919191;
  line-height: 26px;
}

/* line 153, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .copy-right_text {
  padding: 30px;
  background: #222;
  min-height: 122px;
}

.footer .copy-right_text .copy_right {
  font-size: 15px;
  color: #919191;
  margin-bottom: 0;
  font-weight: 500;
}

@media (max-width: 767px) {
  .footer .copy-right_text .copy_right {
    font-size: 13px;
  }
}

.footer .socail_links {
  margin-top: 47px;
}

@media (max-width: 767px) {
  .footer .socail_links {
    margin-top: 30px;
  }
}

/* line 174, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .socail_links ul li {
  display: inline-block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 177, ../../Arafath/CL/Cl November/222. Directory/HTML/scss/_footer.scss */
.footer .socail_links ul li a {
  font-size: 15px;
  width: 38px;
  height: 38px;
  display: inline-block;
  text-align: center;
  background: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  line-height: 40px !important;
  margin-right: 7px;
  color: #ffffff !important;
  line-height: 40px !important;
  background: $primary;
  color: #fff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.footer .socail_links ul li a:hover {
  background: $secondary;
  text-decoration: none;
}

.navbar-toggler {
  background: none;
  border: none;
  right: 15px;
  top: 25px;
  &:active,
  &:focus {
    outline: 0;
  }
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin: 4px 0 4px 0;
    transition: all 0.2s;
    background: $primary;
    &:nth-of-type(1) {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }
    &:nth-of-type(2) {
      opacity: 0;
      filter: alpha(opacity=0);
    }
    &:nth-of-type(3) {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }
  }

  &.collapsed {
    .icon-bar {
      &:nth-of-type(1) {
        transform: rotate(0);
      }
      &:nth-of-type(2) {
        opacity: 1;
        filter: alpha(opacity=100);
      }
      &:nth-of-type(3) {
        transform: rotate(0);
      }
    }
  }
}

.pillar-cta {
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

@media (max-width: 991px) {
  .rtl {
    .navbar-toggler {
      left: 15px;
      right: auto;
    }
    ul {
      &.navbar-nav {
        margin-right: 0 !important;
        margin-left: auto !important;
      }
    }
  }
  .pillar-cta {
    display: block;
    width: 100%;
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $white;
  z-index: 9999999999999;
  &.hide {
    display: none;
  }
}

.loading .middle {
  position: absolute;
  top: 50%;
  width: 100%;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: -20px auto 0;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $primary;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc !important;
  opacity: 1 !important; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:  #ccc !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color:  #ccc !important;
}

header.masthead {
  h1 {
    text-shadow: 0 0 1px #000000;
    line-height: 1.5;
  }
  .search-box-container {
    border: 5px solid #222222;
    .form-control {
      border-radius: 0;
      border: none;
      background: transparent;
    }
  }
  @include media-breakpoint-up(xs) {
    .search-box-container {
      .form-control {
        border-bottom: 1px solid #eee;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.short {
      height: 50vh;
      min-height: 30rem;
      padding-top: 5rem;
      padding-bottom: 0;
      p {
        font-size: 1.15rem;
      }
      h1 {
        font-size: 2rem;
        font-weight: 800 !important;
      }
    }
    .search-box-container {
      border-radius: 10rem;
      .form-control {
        border: none !important;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    &.short {
      h1 {
        font-size: 2.5rem;
        font-weight: 800 !important;
      }
    }
    .search-box-container {
      .form-control {
        border-bottom: 1px solid #eee;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .search-box-container {
      .search-btn {
        border-radius: 50% !important;
        border-left: none;
      }
      .form-control {
        border-bottom: 1px solid #eee;
      }
    }
  }
}

.team {
  background: #f5f5f5;
  .team-member {
    .member-card {
      min-height: 306px;
      img {
        height: 100px;
        background: url("../img/img-loader-bg.gif") 0 0;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .social-link {
        width: 30px;
        height: 30px;
        border: 1px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary;
        border-radius: 50%;
        transition: all 0.3s;
        font-size: 0.9rem;
        &:hover,
        &:focus {
          border-color: $primary;
          background: $primary;
          text-decoration: none;
          color: $white;
        }
        i {
          svg {
            margin-top: 5px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.rsc-container {
  right: 80px !important;
  bottom: 1rem !important;
}
.rsc-float-button {
  padding: 16px;
  bottom: 1rem !important;
  right: 73px !important;
}
.rsc-header-title {
  color: #fff !important;
}
img.rsc-ts-image {
  width: auto !important;
}

.center-iframe > div,
.center-iframe > div > div,
.center-iframe > div > div > div,
.center-iframe > div > div > div > div {
  margin: 0 auto;
}

.card-saa {
  .item {
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
    background: #fff;
    border-radius: 10px;

    .card-img {
      padding: 15px;

      .img {
        overflow: hidden;
      }

      &:hover {
        img {
          transform: scale(1.1, 1.1);
        }
      }

      img {
        transition: all 0.5s;
      }
    }

    .cont {
      padding: 15px 15px 30px;
    }

    .info {
      margin-bottom: 10px;

      a {
        color: #999;
        font-size: 12px;
        margin: 0 5px 5px;
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
      padding: 15px;
      color: #fff;
      background: $primary;
    }

    h6 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .card-saa-footer {
      padding: 15px;
    }
  }
}

/* ----------------------------------------------------------------
     [ 11 Start Price ]
-----------------------------------------------------------------*/

.price {
  .pricing-tables {
    padding: 0 50px;
  }

  .item {
    padding: 30px;
    background: #fff;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .type {
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #eee;

      img {
        position: absolute;
        bottom: -10px;
        left: -30px;
        width: calc(100% + 60px);
      }

      h4 {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .value {
      margin: 30px 0;

      h3 {
        font-size: 55px;

        span {
          font-size: 15px;
        }
      }

      .per {
        font-size: 13px;
        margin-top: 5px;
        color: $primary;
      }
    }

    .features {
      text-align: left;
      overflow: hidden;
      padding: 15px 0;

      ul {
        margin-bottom: 0;
      }

      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .true {
          color: #3ff312;
        }

        .false {
          color: #ec126d;
        }
      }

      i {
        float: right;
        font-size: 16px;
      }
    }

    .order {
      margin-top: 30px;

      .butn {
        box-shadow: none;
      }
    }

    &.active {
      color: #fff;
      position: relative;
      transform: scale(1.05, 1.05);
      z-index: 2;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;

      h3,
      h4 {
        color: #fff !important;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $primary;
        background: linear-gradient(to bottom, $primary, #6976c5);
        z-index: -1;
        opacity: 0.9;
      }

      .value {
        .per {
          color: #eee;
        }
      }
    }
  }

  .fa-check:before {
    content: "✔";
  }
  .fa-times:before {
    content: "✘";
  }
}

/* ----------------------------------------------------------------
     [ End Price ]
-----------------------------------------------------------------*/

// .saa-search-icon svg {
//   position: absolute;
//   top: 8px;
//   left: 12px;
// }

// .ctas {
//   &.btn-group {
//     button {
//       border: 1px solid #ffffff;
//     }
//   }
// }

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 3em;
  overflow: visible;
  vertical-align: -0.125em;
}

.saa-list-main-template {
  background: #fff;
  padding: 10px 20px;
  .saa-loading,
  .table-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -105px;
    margin-top: 0;
    text-align: center;
    padding: 1em 0px;
    z-index: 3;
  }
  .top {
    background: $primary;
  }
  .filter-header {
    padding: 5px;
    h3 {
      color: #fff;
      padding: 8px 15px;
      font-size: 1.65rem;
      margin-bottom: 0;
      i {
        font-size: 1.4rem;
        margin-right: 10px;
      }
    }
  }
  .search-box {
    padding: 5px;
    .input-group {
      background: #fff;
    }
  }
  .filters {
    padding: 5px;
  }
  .controls-top {
    padding: 5px;
  }
  .controls-bottom {
    padding: 5px;
  }
  .search-data {
    padding: 5px;
    .table-container {
      ul.list-group {
        li.list-group-item {
          position: relative;
          display: block;
          padding: 0 1.25rem;
          margin-bottom: 4px;
          background-color: #fdfdfd;
          border: none;
        }
      }
    }
  }
  .badge-notify {
    position: relative;
    top: -20px;
    left: -35px;
  }
  .image-container {
    background: #f5f5f5;
    .mask-image {
      border: none;
      position: absolute;
      height: 70px;
      width: 32px;
      right: 20%;
      bottom: 2px;
      display: block;
      &.mask-no-color {
        // background: url("../assets/images/colors/no-color.png");
      }
      &.mask-red {
        // background: url("../assets/images/colors/red.png");
      }
      &.mask-white {
        // background: url("../assets/images/colors/white.png");
      }
      &.mask-rose {
        // background: url("../assets/images/colors/rose.png");
      }
    }
  }
}

.flag-select__btn {
  outline: none;
}
.flag-select__options {
  width: 100%;
  text-align: center;
}
.flag-select__option__icon {
  width: 1.7em;
  height: 1.7em;
}

.rtl
  .btn-group
  > .btn.btn-xl:first-child:not(:last-child):not(.dropdown-toggle),
[dir="rtl"]
  .btn-group
  > .btn.btn-xl:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 10rem 10rem 0;
}

.rtl .btn-group > .btn.btn-xl:last-child:not(:first-child),
.rtl .btn-group > .dropdown-toggle:not(:first-child),
[dir="rtl"] .btn-group > .btn.btn-xl:last-child:not(:first-child),
[dir="rtl"] .btn-group > .dropdown-toggle:not(:first-child) {
  border-radius: 10rem 0 0 10rem;
}

.page-section {
  padding: 4rem 0;
  &.top {
    margin-top: 4rem;
  }
}

.category-card {
  padding: 4em;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  background: #fff;
  &:hover {
    box-shadow: 0px 8px 30px rgba(180, 10, 10, 0.37);
  }
}

// .rc-anchor-light {
//   background: transparent;
//   &.rc-anchor-normal{
//     border: none;
//   }
// }

.recaptcha-wrapper {
  overflow: hidden;
  background-color: transparent;
  border-radius: 3px;
  position: relative;
  margin-top: 17px;
  border: 1px solid transparent;
  color: #000;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
  }
}

.rc-anchor-normal {
  height: auto !important;
  width: 100% !important;
}
.recaptcha-info {
  background-size: 32px;
  height: 32px;
  margin: 0 13px 0 13px;
  position: absolute;
  right: 0;
  top: 9px;
  width: 32px;
  background-image: url(https://www.gstatic.com/recaptcha/api2/logo_48.png);
  background-repeat: no-repeat;
}

body {
  direction: ltr !important;
}
.container {
  direction: ltr !important;
}
.rtl {
  body {
    direction: rtl !important;
  }
  .container {
    direction: rtl !important;
  }
  .recaptcha-wrapper {
    text-align: right !important;
  }
  .recaptcha-info {
    right: 0;
  }
}
.rc-anchor-logo-text {
  color: #9b9b9b;
  cursor: default;
  // font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  margin-top: 5px;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 37px;
}
.rc-anchor-checkbox-label {
  // font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  left: 50px;
  top: 26px;
  position: absolute;
  color: black;
}
.rc-anchor .rc-anchor-normal .rc-anchor-light {
  border: none;
}
.rc-anchor-pt {
  color: #9b9b9b;
  // font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 8px;
  font-weight: 400;
  right: 10px;
  top: 53px;
  position: absolute;
  a:link {
    color: #9b9b9b;
    text-decoration: none;
  }
}

.g-recaptcha {
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  display: inline-block;

  // transform: scale(0.88);
  // -webkit-transform: scale(0.88);
  // transform-origin: 0 0;
  // -webkit-transform-origin: 0 0;
}

.secondary-color-border {
  border-color: $secondary !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    margin-top: 10px !important;
    border: none;
    border-radius: 0;
    -moz-box-shadow: 0px 8px 7px rgba(0, 0, 0, 0.15) !important;
    -webkit-box-shadow: 0px 8px 7px rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0px 8px 7px rgba(0, 0, 0, 0.15) !important;
  }

  .donation-block {
    position: absolute;
    margin-top: -136px;
  }
}

.saa_card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 2rem;
  box-shadow: 0px 1rem 1.5rem rgba(167, 167, 167, 0.5);
  width: 100%;
  .banner {
    height: 5rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    svg {
      padding: 10px;
      background-color: #fff;
      width: 5rem;
      height: 5rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      transform: translateY(50%);
      transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
      &:hover {
        transform: translateY(50%) scale(1.3);
      }
    }
  }
  h2 {
    &.name {
      text-align: center;
      padding: 0 2rem 0.5rem;
      margin: 0;
      font-size: 1.5rem;
    }
  }
  .title {
    color: #a0a0a0;
    font-size: 0.85rem;
    text-align: center;
    padding: 0 2rem 1.2rem;
  }
  .details {
    padding: 0 2rem 1.2rem;
    display: flex;
    flex-direction: column;
    order: 99;
    .info {
      padding: 0 0 1rem;
      display: flex;
      h2 {
        text-align: center;
        width: 50%;
        margin: 0;
        box-sizing: border-box;
        text-decoration: none;
        padding: 0.8rem;
        display: flex;
        flex-direction: column;
        border-radius: 0.8rem;
        transition: background-color 100ms ease-in-out;
        background-color: #f8f8f8;
        span {
          color: $secondary;
          font-weight: bold;
          transform-origin: bottom;
          transition: color 100ms ease-in-out;
        }
        small {
          color: #afafaf;
          font-size: 0.85rem;
          font-weight: normal;
          background-color: #f8f8f8;
        }
      }
    }
  }
}

.rounded-corners {
  border-radius: 1.5rem/50%;
}

.list-type-list {
  .list-group-item {
    padding: 0;
    border: none;
    border-bottom: 1px solid #f0f0f0;
  }

  .item-actions {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 0.35rem 0.5rem;
  font-size: 0.775rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.entry-card {
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
}

.entry-content {
  background-color: #fff;
  padding: 36px 36px 36px 36px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.entry-content .entry-title a {
  color: #333;
}

.entry-content .entry-title a:hover {
  color: #4782d3;
}

.entry-content .entry-meta span {
  font-size: 12px;
}

.entry-title {
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.entry-thumb {
  display: block;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.entry-thumb img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.entry-thumb .thumb-hover {
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(71, 130, 211, 0.85);
  display: block;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 40px;
  line-height: 100px;
  border-radius: 50%;
  margin-top: -50px;
  margin-left: -50px;
  text-align: center;
  transform: scale(0);
  -webkit-transform: scale(0);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.entry-thumb:hover .thumb-hover {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
}

.article-post {
  border-bottom: 1px solid #eee;
  padding-bottom: 70px;
}

.article-post .post-thumb {
  display: block;
  position: relative;
  overflow: hidden;
}

.article-post .post-thumb .post-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  opacity: 0;
}

.article-post .post-thumb .post-overlay span {
  width: 100%;
  display: block;
  vertical-align: middle;
  text-align: center;
  transform: translateY(70%);
  -webkit-transform: translateY(70%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  height: 100%;
  color: #fff;
}

.article-post .post-thumb:hover .post-overlay {
  opacity: 1;
}

.article-post .post-thumb:hover .post-overlay span {
  transform: translateY(50%);
  -webkit-transform: translateY(50%);
}

.posts {
  .post {
    .content {
      .post-header {
        .post-title {
          font-weight: 500;
        }
        ul{
          &.breadcrumb {
            font-size: 13px;
            background-color: #f5f5f5;
            border-bottom: 1px solid #eee;
            padding-top: 15px;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.share-buttons li {
  vertical-align: middle;
}

.share-buttons li a {
  margin-right: 0px;
}

.post-content .fa {
  color: #ddd;
}

.post-content a h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0px;
}

.article-post .owl-carousel {
  margin-bottom: 20px !important;
}

.post-masonry h4 {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 700;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.media-body h5 a {
  color: #555;
}
.categories li a:before {
  content: "\f0da";
  font-family: "FontAwesome";
  margin-right: 5px;
}
/*
Template sidebar
*/

.sidebar-title {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.categories li {
  vertical-align: middle;
}

.categories li > ul {
  padding-left: 15px;
}

.categories li > ul > li > a {
  font-weight: 300;
}

.categories li a {
  color: #999;
  position: relative;
  display: block;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
}

.categories li a:before {
  content: "\f0da";
  font-family: "FontAwesome";
  margin-right: 5px;
}

.categories li a:hover {
  color: #444;
  background-color: #f5f5f5;
}

.categories > li.active > a {
  font-weight: 600;
  color: #444;
}

.media-body h5 {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 20px;
  font-weight: 400;
}

.media-body h5 a {
  color: #555;
}

.media-body h5 a:hover {
  color: #4782d3;
}

.img-profile {
  height: 2rem;
  width: 2rem;
}

.bg-gray {
  background: #f7f7f7;
}

.page-section-img-container {
  text-align: center;
}

.flipped-container-row {
  direction: rtl;
}
.non-flipped-container-row {
  direction: ltr;
}
.btn-xl {
  font-size: 0.75rem;
}
.rtl {
  .btn-xl {
    font-size: 0.85rem;
  }
  .flipped-container-row {
    direction: ltr;
  }
  .non-flipped-container-row {
    direction: rtl;
  }
}

@media (min-width: 992px) {
  .page-section-img-container {
    text-align: right;
    &.flipped {
      text-align: left;
    }
  }
  .rtl {
    .page-section-img-container {
      text-align: left;
      &.flipped {
        text-align: right;
      }
    }
  }
}

.content h1 {
  padding-bottom: 10px;
  margin-bottom: 15px;
  display: inline-flex;
  border-bottom: 3px solid #eee;
}

/*Invoice*/
.invoice .top-left {
  font-size: 65px;
  color: #3ba0ff;
}

.invoice .top-right {
  text-align: right;
  padding-right: 20px;
}

.invoice .table-row {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 25px;
}

.invoice .payment-info {
  font-weight: 500;
}

.invoice .table-row .table > thead {
  border-top: 1px solid #ddd;
}

.invoice .table-row .table > thead > tr > th {
  border-bottom: none;
}

.invoice .table > tbody > tr > td {
  padding: 8px 20px;
}

.invoice .invoice-total {
  margin-right: -10px;
  font-size: 16px;
}

.invoice .last-row {
  border-bottom: 1px solid #ddd;
}

.invoice-ribbon {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -1px;
  right: 14px;
}

.ribbon-inner {
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -5px;
  top: 11px;
  width: 120px;
  background-color: #66c591;
  font-size: 15px;
  color: #fff;
}

.ribbon-inner:before,
.ribbon-inner:after {
  content: "";
  position: absolute;
}

.ribbon-inner:before {
  left: 0;
}

.ribbon-inner:after {
  right: 0;
}

@media (max-width: 575px) {
  .invoice .top-left,
  .invoice .top-right,
  .invoice .payment-details {
    text-align: center;
  }

  .invoice .from,
  .invoice .to,
  .invoice .payment-details {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }

  .invoice p.lead,
  .invoice .from p.lead,
  .invoice .to p.lead,
  .invoice .payment-details p.lead {
    font-size: 22px;
  }

  .invoice .btn {
    margin-top: 10px;
  }
}

@media print {
  .invoice {
    width: 900px;
    height: 800px;
  }
}

@media (min-width: 768px) {
  .redux-toastr .top-center {
    width: 500px;
    margin-left: -250px;
  }
}

.bi {
  width: 1em;
  height: 1em;
  vertical-align: -0.1em;
  font-size: 1.75rem;
}

.rrt-title {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid;
}

.rrt-text {
  padding: 10px 0;
}

.close-toastr span {
  padding: 10px;
  width: 30px;
}

.header-not-centered {
  text-align: center;
}
@media (min-width: 992px) {
  .header-not-centered {
    text-align: left;
  }
  hr {
    &.divider {
      &.not-centered {
        width: 3.25rem;
        text-align: left;
        float: left;
      }
    }
  }

  .rtl {
    .header-not-centered {
      text-align: right;
    }
    hr {
      &.divider {
        &.not-centered {
          text-align: right;
          float: right;
        }
      }
    }
  }
}

.rtl {
  .redux-toastr .toastr .rrt-middle-container {
    text-align: right;
  }

  .list-inline-item:not(:last-child) {
    margin-right: 0;
    margin-left: 0.5rem;
  }
}

.app-paypal-container {
  background: #fff !important;
  padding: 15px !important;
  z-index: 1;
  position: inherit;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

.home-contact-icon {
  font-size: 3rem;
  margin-bottom: 20px;
  color: $primary;
}

$border-radius-size: 14px;

*,
*:before,
*:after {
  box-sizing: border-box;
}

.slide-container {
  margin: auto;
  width: 600px;
  text-align: center;
}

.wrapper {
  padding-top: 40px;
  padding-bottom: 40px;

  &:focus {
    outline: 0;
  }
}

.progress-bar {
  .progress-percentage {
    padding: 6px 0;
    font-size: 11px;
    position: absolute;
    color: $primary;
    background: #fff;
    border: 1px solid #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    left: 50%;
    margin-left: -15px;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
  }
}

.stats-card {
  background-image: linear-gradient(
    to bottom,
    #{fade-out(#fff, 0.1)} 0%,
    #{fade-out($primary, 0.1)} 100%
  );
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  display: inline-block;
  margin: auto;
  border-radius: $border-radius-size + 5;
  position: relative;
  text-align: center;
  box-shadow: -1px 15px 30px -12px black;
  z-index: 9999;
  padding-top: 100px;
}

.stats-card__image {
  position: relative;
  width: 230px;
  height: 230px;
  margin-bottom: 35px;
  border-top-left-radius: $border-radius-size;
  border-top-right-radius: $border-radius-size;
  img {
    width: 400px;
    position: absolute;
    top: -65px;
    left: -70px;
  }
}

.stats-card__level {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3px;
}

.stats-card__unit-name {
  font-size: 20px;
  font-weight: 500;
  color: $secondary;
  border-bottom: 1px solid $secondary;
  background: #fff;
  margin-bottom: 5px;
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  border-top-left-radius: $border-radius-size;
  border-top-right-radius: $border-radius-size;
  padding: 15px;
}

.stats-card__unit-description {
  padding: 20px;
  margin-bottom: 10px;
  min-height: 175px;
}

.stats-card__unit-stats {
  background: $primary;

  color: white;
  font-weight: 700;
  border-bottom-left-radius: $border-radius-size;
  border-bottom-right-radius: $border-radius-size;

  .one-third {
    width: 33%;
    float: left;
    padding: 20px 15px;
    border-right: 1px solid darken($primary, 6%);
  }

  sup {
    position: absolute;
    bottom: 4px;
    font-size: 45%;
    margin-left: 2px;
  }

  .stat {
    position: relative;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }

  .stat-value {
    text-transform: lowercase;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }

  .no-border {
    border-right: none;
  }
}

.rtl {
  .stats-card__unit-stats {
    .one-third {
      float: right;
      border-left: 1px solid darken($primary, 6%);
      border-right: none;
    }

    sup {
      margin-left: auto;
      margin-right: 2px;
    }

    .stat {
      direction: ltr;
    }

    .no-border {
      border-left: none;
    }
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.slick-prev {
  left: 100px;
  z-index: 999;
}

.slick-next {
  right: 100px;
  z-index: 999;
}

.card-stats {
  border: 5px solid #fff;
}
.card-stats .img-wrap {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  position: relative;
  height: 220px;
  text-align: center;
}
.card-stats .img-wrap img {
  min-height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.card-stats .info-wrap {
  overflow: hidden;
  padding: 15px;
  border-top: 1px solid #eee;
  min-height: 105px;
}
.card-stats .bottom-wrap {
  padding: 15px;
  border-top: 1px solid #eee;
}

.label-rating {
  margin-right: 10px;
  color: #333;
  display: inline-block;
  vertical-align: middle;
}

.card-stats .price-old {
  color: #999;
}

.card-stats {
  border-top-left-radius: $border-radius-size;
  border-top-right-radius: $border-radius-size;
  border-bottom-left-radius: $border-radius-size;
  border-bottom-right-radius: $border-radius-size;
  h1 {
    font-size: 20px;
    font-weight: 500;
    padding: 20px 15px 15px;
    text-align: center;
  }

  .progress {
    border-radius: 0;
    height: 0.5rem;
  }
}

.floating-btn{
	position:fixed;
	bottom:40px;
	right:40px;
	text-align:center;
  box-shadow: 2px 2px 3px #999;
  z-index: 5;
}
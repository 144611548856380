// Global styling for this theme

body,
html {
    width: 100%;
    height: 100%;
    font-family: $font-family-sans-serif;
}

//- Custom horizontal rules

hr.divider {
    max-width: 3.25rem;
    border-width: 0.2rem;
    border-color: $primary;
}

hr.light {
    border-color: $white;
}

//- Button restyling

.btn {
    font-family: $font-family-sans-serif;
}

//- Custom XL button styling

.btn-xl {
    padding: 1.25rem 2.25rem;
    font-size: 0.85rem;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 10rem;
}

//- Page section styling

.page-section {
    padding: 8rem 0;
}
